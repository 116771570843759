import React, { useLayoutEffect, useRef } from 'react'
import { MdHexagon } from "react-icons/md";
import './Marquee.scss'
import './MarqueeMobile.scss'
import  { gsap } from 'gsap'

function Marquee() {
    const main = useRef(null)
    useLayoutEffect(()=>{
        let ctx = gsap.context(()=>{
            gsap.from(main.current,{
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: main.current,
                    start: "top 75%",
                }
            })
        },main.current)
        return ()=> ctx.revert()
    }, [])
    return (
        <div className='marquee-container' ref={main}>
            <div className="marquee-box-container">
                <div className="marquee-text forward">
                    <div className='text-marquee-con'>
                        <p className="jadbery">JADBERY</p>
                        <MdHexagon className='hex' />
                    </div>
                    <div className='text-marquee-con'>
                        <p className="jadbery">JADBERY</p>
                        <MdHexagon className='hex' />
                    </div>
                    <div className='text-marquee-con'>
                        <p className="jadbery">JADBERY</p>
                        <MdHexagon className='hex' />
                    </div>
                    <div className='text-marquee-con'>
                        <p className="jadbery">JADBERY</p>
                        <MdHexagon className='hex' />
                    </div>
                </div>
                <div className="marquee-text forward">
                    <div className='text-marquee-con'>
                        <p className="jadbery">JADBERY</p>
                        <MdHexagon className='hex' />
                    </div>
                    <div className='text-marquee-con'>
                        <p className="jadbery">JADBERY</p>
                        <MdHexagon className='hex' />
                    </div>
                    <div className='text-marquee-con'>
                        <p className="jadbery">JADBERY</p>
                        <MdHexagon className='hex' />
                    </div>
                    <div className='text-marquee-con'>
                        <p className="jadbery">JADBERY</p>
                        <MdHexagon className='hex' />
                    </div>
                </div>
            </div>
            <div className="marquee-box-container">
                <div className="marquee-text backward">
                    <div className='text-marquee-con'>
                        <p className="jadbery-subtitle">From Imagination To Innovation</p>
                        <MdHexagon className='hex' />
                    </div>
                    <div className='text-marquee-con'>
                        <p className="jadbery-subtitle">From Imagination To Innovation</p>
                        <MdHexagon className='hex' />
                    </div>
                    <div className='text-marquee-con'>
                        <p className="jadbery-subtitle">From Imagination To Innovation</p>
                        <MdHexagon className='hex' />
                    </div>
                    <div className='text-marquee-con'>
                        <p className="jadbery-subtitle">From Imagination To Innovation</p>
                        <MdHexagon className='hex' />
                    </div>
                </div>
                <div className="marquee-text backward">
                    <div className='text-marquee-con'>
                        <p className="jadbery-subtitle">From Imagination To Innovation</p>
                        <MdHexagon className='hex' />
                    </div>
                    <div className='text-marquee-con'>
                        <p className="jadbery-subtitle">From Imagination To Innovation</p>
                        <MdHexagon className='hex' />
                    </div>
                    <div className='text-marquee-con'>
                        <p className="jadbery-subtitle">From Imagination To Innovation</p>
                        <MdHexagon className='hex' />
                    </div>
                    <div className='text-marquee-con'>
                        <p className="jadbery-subtitle">From Imagination To Innovation</p>
                        <MdHexagon className='hex' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marquee