import React from 'react'
import './Footer.scss'
import './FooterMobile.scss'
import logo from '../assets/logoto.svg'
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa6"

function Footer() {
    return (
        <footer className='footer-main-container'>
            <div className="footer-container">
                <div className="footer-top">
                    <div className="useful-links">
                        <h3 className='useful-title'>Useful Links</h3>
                        <ul>
                            <li><a href='#home'>Home</a></li>
                            <li><a href='#about'>About Us</a></li>
                            <li><a href='#service'>Our Services</a></li>
                            <li><a href='#whyus'>Why Choose Us?</a></li>
                        </ul>
                    </div>
                    <div className="footer-logo-container">
                        <img src={logo} className='footer-logo' />
                    </div>
                    <div className="footer-contacts">
                        <div className="footer-address-container">
                            <h3 className='social-container'>Contact</h3>
                            <ul className="footer-address">
                                <li>Aysha Complex, Perinthalmanna</li>
                                <li>Kerala, India</li>
                                <li>ZIP: 679322</li>
                                <li><a href='mailto:marketing@jadbery.com'>marketing@jadbery.com</a></li>
                                <li><a href='tel:+917306858170'>Phone: +91 73068 58170</a></li>
                            </ul>
                            <div className="footer-socials">
                                <a target='_blank' href='https://instagram.com/jadbery.official'><FaInstagram className='footer-social-icon' /></a>
                                <a target='_blank' href='https://facebook.com/jadbery.official'><FaFacebook className='footer-social-icon' /></a>
                                <a target='_blank' href='https://linkedin.com/jadbery.official'><FaLinkedin className='footer-social-icon' /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <p className="copyright">&copy; 2023 Jadbery. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer