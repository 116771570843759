import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import './About.scss'
import './AboutMobile.scss'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import logo from '../assets/logo.mp4'
import logoW from '../assets/logo.webm'

function About() {
    const main = useRef(null)
    const aboutContent = useRef(null)
    const aboutText = useRef(null)
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        let mm = gsap.matchMedia()
        mm.add("(max-width: 478px)", () => {
            gsap.from(".about-header", {
                y: 100,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: ".about-header",
                    start: "top 75%",
                }
            })
            gsap.from(".about-content-image", {
                y: 100,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: ".about-content-image",
                    start: "top 75%",
                }
            })
            gsap.from(aboutText.current.firstChild, {
                y: 100,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: aboutText.current.firstChild,
                    start: "top 75%",
                }
            })
            gsap.from(aboutText.current.lastChild, {
                y: 100,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: aboutText.current.lastChild,
                    start: "top 75%",
                }
            })
        })
        mm.add("(min-width: 479px)", () => {
            gsap.from(".about-header", {
                y: 100,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: ".about-header",
                    start: "top 75%",
                }
            })
            gsap.to(".about-content-image", {
                scrollTrigger: {
                    trigger: ".about-content-image",
                    start: "top 20%",
                    anticipatePin: 1,
                    // endTrigger: ".about-content-t2",
                    // markers: true,
                    // end: "bottom 20%",
                    end: () => `+=${aboutText.current.lastChild.offsetHeight + 40}px 20%`,
                    scrub: true,
                    pinSpacing: false,
                    pin: true,
                }
            })
            gsap.from(".about-content-image", {
                y: 100,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: ".about-content-image",
                    start: "top 75%",
                }
            })
            gsap.from(aboutText.current, {
                y: 100,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: aboutText.current,
                    start: "top 75%",
                }
            })

        })
        return () => mm.revert()
    }, [])
    return (
        <div className='about-main' ref={main}>
            <div className='about-container'>
                <div className="about-header">
                    <h1 className="about">About Us</h1>
                </div>
                <div className="about-content" ref={aboutContent}>
                    <div className="about-content-image">
                        <video autoPlay loop muted playsInline disableRemotePlayback className='about-logo-vid'>
                            <source src={logo} type='video/mp4' />
                            <source src={logoW} type='video/webm' />
                        </video>
                    </div>
                    <div className="about-content-text" ref={aboutText}>
                        <p className='about-content-t1'>We're a dedicated team of professionals who are passionate about turning your digital dreams into reality.</p>
                        <p className='about-content-t2'>With a passion for technology and a wealth of experience in web and app development, we are your trusted partner for digital solutions.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About