import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './App.scss';
import Navbar from './components/navbar/Navbar';
import { useWindowSize } from 'react-use';
import Main from './components/Main'
import { gsap } from 'gsap';
import logo from './components/assets/logoblack.svg'
import { Routes, Route, Navigate } from 'react-router-dom'
import whatsapp from './components/assets/whatsapp.png'
import PaycodPolicy from './components/paycod/PaycodPolicy';
import AutoChatPolicy from './components/paycod/AutoChatPolicy';

function App() {
    const { width, height } = useWindowSize()
    const app = useRef(null)
    const [navPrev, setNavPrev] = useState(0)
    const [navCurrent, setNavCurrent] = useState(0)
    useEffect(() => {
        let vh = height * 0.01
        document.documentElement.style.setProperty("--browser", `${vh}px`)
    }, [height, width])
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            gsap.from(".header-logo", {
                opacity: 0,
                y: -50,
                duration: 1,
            })
        }, app.current)
        return () => ctx.revert()
    }, [])
    const openWhatsapp = () => {
        let link = "https://web.whatsapp.com/send"
        if (width < 500) {
            link = "https://api.whatsapp.com/send"
        }
        let url = `${link}/?phone=7306858170`;
        window.open(url, "_blank");
    }
    return (
        <Routes>
            <Route path='/' element={
                <div className="App" ref={app}>
                    <Navbar navPrev={navPrev} setNavPrev={setNavPrev} navCurrent={navCurrent} setNavCurrent={setNavCurrent} />
                    <img src={logo} className='header-logo' />
                    <a onClick={openWhatsapp} className='whatsapp-button'><img src={whatsapp} className='whatsapp' /></a>
                    <Main navPrev={navPrev} setNavPrev={setNavPrev} navCurrent={navCurrent} setNavCurrent={setNavCurrent} />
                </div>
            } />
            <Route path='/paycod-privacy-policy' element={<PaycodPolicy />} />
            <Route path='/autochat-privacy-policy' element={<AutoChatPolicy />} />
            <Route path='*' element={<Navigate to={"/"} />} />
        </Routes>
    );
}

export default App;
