import './Services.scss'
import './ServicesMobile.scss'
import React, { useLayoutEffect, useRef } from 'react'
import { gsap } from 'gsap'
import webvid from '../assets/web.mp4'
import applevid from '../assets/apple.mp4'
import androidvid from '../assets/android.mp4'
import webvidW from '../assets/web.webm'
import applevidW from '../assets/apple.webm'
import androidvidW from '../assets/android.webm'
import { useWindowSize } from 'react-use'

function Services() {
    const webTags = ["Responsive Web Design", "E-commerce Development", "UI/UX Design", "Web Security", "Web Consultancy", "Web Analytics Integration", "Performance Optimization", "Search Engine Optimization"]
    const androidTags = ["Native App Development", "Flutter", "React Native", "App Maintenance and Updates", "UI/UX Design", "Android Wearable App Development", "Augmented Reality", "Security Integration", "App Testing Quality Assurance", "Push Notification Integration"]
    const androidTagsMobile = ["Native App Development", "Flutter", "React Native", "UI/UX Design", "App Maintenance and Updates", "Android Wearable App Development", "Augmented Reality", "Security Integration", "App Testing Quality Assurance", "Push Notification Integration"]
    const appleTags = ["Native App Development", "Flutter", "React Native", "UI/UX Design", "App Maintenance and Updates", "Apple Watch App Development", "Augmented Reality", "Security Integration", "App Testing Quality Assurance", "Push Notification Integration"]
    const main = useRef(null)
    const serviceH = useRef(null)
    const serviceSub = useRef(null)
    useLayoutEffect(() => {
        let [webCon, androidCon, appleCon] = serviceSub.current.children
        let mm = gsap.matchMedia()
        mm.add("(max-width: 478px)",()=>{
            gsap.from(".services-heading", {
                y: 100,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: ".services-heading",
                    start: "top 75%",
                }
            })
            gsap.from(webCon, {
                y: 100,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: webCon,
                    start: "top 75%",
                }
            })
            gsap.from(androidCon, {
                y: 100,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: androidCon,
                    start: "top 75%",
                }
            })
            gsap.from(appleCon, {
                y: 100,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: appleCon,
                    start: "top 75%",
                }
            })

        })
        mm.add("(min-width: 479px)", () => {
            gsap.to(serviceH.current, {
                scrollTrigger: {
                    trigger: serviceH.current,
                    start: "top 10%",
                    endTrigger: appleCon,
                    end: "bottom center",
                    scrub: true,
                    // anticipatePin: 1,
                    pin: true,
                }
            })
            gsap.from(webCon, {
                scrollTrigger: {
                    trigger: webCon,
                    start: "top 20%",
                    endTrigger: appleCon,
                    end: "bottom center",
                    scrub: true,
                    // anticipatePin: 1,
                    pin: true,
                    pinSpacing: false,
                }
            })
            gsap.from(androidCon, {
                scrollTrigger: {
                    trigger: androidCon,
                    start: "top 20%",
                    end: "bottom center",
                    endTrigger: appleCon,
                    scrub: true,
                    // anticipatePin: 1,
                    pin: true,
                    pinSpacing: false,
                }
            })
            gsap.from(appleCon, {
                scrollTrigger: {
                    trigger: appleCon,
                    start: "top 20%",
                    scrub: true,
                    // anticipatePin: 1,
                    pin: true,
                    pinSpacing: true,
                    end: "bottom center",
                }
            })

        })
        return () => {
            mm.revert()
        }
    }, [])
    const {width, height} = useWindowSize()
    return (
        <div className='service-main' ref={main}>
            <div className='services-main-container'>
                <h1 className='services-heading' ref={serviceH}>Our Services</h1>
                <div className='service-sub-container' ref={serviceSub}>
                    <div className='service-container'>
                        <div className="service-main-container">
                            <div className="service-image">
                                <div className="service-image-subcontainer">
                                    <video autoPlay loop muted playsInline disableRemotePlayback className='service-vid'>
                                        <source src={webvid} type='video/mp4' />
                                        <source src={webvidW} type='video/webm' />
                                    </video>
                                </div>
                            </div>
                            <div className="service-content">
                                <div className='service-heading'>Web Development</div>
                                <div className="service-tags">
                                    {webTags.map((val, ind) => {
                                        return <div className='service-tag' key={ind}><p>{val}</p></div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='service-container'>
                        <div className="service-main-container">
                            <div className="service-image">
                                <div className="service-image-subcontainer">
                                    <video autoPlay loop muted playsInline className='service-vid'>
                                        <source src={androidvid} type='video/mp4' />
                                        <source src={androidvidW} type='video/webm' />
                                    </video>
                                </div>
                            </div>
                            <div className="service-content">
                                <div className='service-heading'>Android App Development</div>
                                <div className="service-tags">
                                    {width>479 && androidTags.map((val, ind) => {
                                        return <div className='service-tag' key={ind}><p>{val}</p></div>
                                    })}
                                    {width<480 && androidTagsMobile.map((val, ind) => {
                                        return <div className='service-tag' key={ind}><p>{val}</p></div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='service-container'>
                        <div className="service-main-container">
                            <div className="service-image">
                                <div className="service-image-subcontainer">
                                    <video autoPlay loop muted playsInline className='service-vid'>
                                        <source src={applevid} type='video/mp4' />
                                        <source src={applevidW} type='video/webm' />
                                    </video>
                                </div>
                            </div>
                            <div className="service-content">
                                <div className='service-heading'><span className='ios'>iOS</span> App Development</div>
                                <div className="service-tags">
                                    {appleTags.map((val, ind) => {
                                        return <div className='service-tag' key={ind}><p>{val}</p></div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Services