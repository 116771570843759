import React, { useLayoutEffect, useRef } from 'react'
import './Hero.scss'
import './HeroMobile.scss'
import Bg from '../assets/bg.mp4'
import BgW from '../assets/bg.webm'
import { gsap } from 'gsap'

function Hero() {
    const main = useRef(null)
    const text = useRef(null)
    useLayoutEffect(()=>{
        let ctx = gsap.context(()=>{
            let ch = gsap.utils.toArray(text.current.children)
            gsap.from(ch,{
                opacity: 0,
                y: 100,
                stagger: 1,
                duration: 1,
            })
        }, main.current)
        return ()=> ctx.revert()
    }, [])
    return (
        <div className='hero-container' ref={main}>
            <video autoPlay loop muted playsInline disableRemotePlayback className='hero-bg-video'>
                <source src={Bg} type='video/mp4' />
                <source src={BgW} type='video/webm' />
            </video>
            <div className='hero-text' ref={text}>
                <h1 className='jadbery'>
                    JADBERY
                </h1>
                <h3 className='jadbery-subtitle'>
                    From Imagination To Innovation
                </h3>
            </div>
        </div>
    )
}

export default Hero