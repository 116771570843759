import React, { useEffect, useLayoutEffect, useRef } from 'react'
import './Main.scss'
import { gsap } from 'gsap'
import Hero from './hero/Hero'
import About from './about/About'
import Services from './services/Services'
import Why from './why/Why'
import Contact from './contact/Contact'
import Footer from './footer/Footer'
import Marquee from './marquee/Marquee'

function Main({navPrev, setNavPrev, navCurrent, setNavCurrent}) {
    const main = useRef(null)
    const hero = useRef(null)
    const about = useRef(null)
    const service = useRef(null)
    const why = useRef(null)
    const contact = useRef(null)
    const handleToggle = (cur) =>{
        if(navCurrent!==navPrev){
            setNavPrev(navCurrent)
        }
        setNavCurrent(cur)
    }
    useLayoutEffect(()=>{
        let ctx = gsap.context(()=>{

            gsap.to("#hero",{
                scrollTrigger: {
                    trigger: "#hero",
                    start: "top center",
                    end: "clamp(bottom center)",
                    // markers: true,
                    onToggle: ({isActive}) => isActive && handleToggle(0),
                }
            })
            gsap.to("#about",{
                scrollTrigger: {
                    trigger: "#about",
                    start: "top center",
                    end: "clamp(bottom center)",
                    // markers: true,
                    onToggle: ({isActive}) => isActive && handleToggle(1),
                }
            })
            gsap.to("#services",{
                scrollTrigger: {
                    trigger: "#services",
                    start: "top center",
                    end: "clamp(bottom center)",
                    // markers: true,
                    onToggle: ({isActive}) => isActive && handleToggle(2),
                }
            })
            gsap.to("#whyus",{
                scrollTrigger: {
                    trigger: "#whyus",
                    start: "top center",
                    end: "clamp(bottom center)",
                    // markers: true,
                    onToggle: ({isActive}) => isActive && handleToggle(3),
                }
            })
            gsap.to("#contact",{
                scrollTrigger: {
                    trigger: "#contact",
                    start: "top center",
                    end: "clamp(bottom center)",
                    // markers: true,
                    onToggle: ({isActive}) => isActive && handleToggle(4),
                }
            })
            // gsap.to("#background-blob", {
            //     scrollTrigger: {
            //         trigger: "#background-blob",
            //         start: "top top",
            //         end: "bottom",
            //         endTrigger: "#contact",
            //         pin: true,
            //         markers: true,

            //     }
            // })
        },main.current)
        return ()=> ctx.revert()
    }, [])
    return (
        <div className='main' ref={main}>
            <section id="hero" className='hero-section' ref={hero}>
                <Hero />
            </section>
            <section id="about" className='about-section' ref={about}>
                <About />
            </section>
            <section id="services" className='services-section' ref={service}>
                <Services />
            </section>
            <section id="whyus" className='why-us-section' ref={why}>
                <Why />
            </section>
            <Marquee /> 
            <section id="contact" className='contact-section' ref={contact}>
                <Contact />
            </section>
            <Footer />
        </div>

    )
}

export default Main