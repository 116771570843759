import React, { useLayoutEffect, useRef } from 'react'
import './Why.scss'
import './WhyMobile.scss'
import bulb from '../assets/bulb.mp4'
import puzzle from '../assets/puzzle.mp4'
import money from '../assets/money.mp4'
import bulbW from '../assets/bulb.webm'
import puzzleW from '../assets/puzzle.webm'
import moneyW from '../assets/money.webm'
import { gsap } from 'gsap'

function Why() {
    const data = [
        {
            title: 'Innovative Solutions',
            content: 'Elevate your business with cutting-edge and inventive solutions. Our team thrives on creativity, delivering forward-thinking approaches that set your project apart in the digital landscape.',
            vid: bulb,
            vidW: bulbW,
        },
        {
            title: 'Cost-Effective Solutions',
            content: 'Achieve optimal results without breaking the bank. We specialize in providing high-quality solutions that are not only effective but also budget-friendly, ensuring maximum value for your investment.',
            vid: money,
            vidW: moneyW,
        },
        {
            title: 'Proactive Problem-Solving',
            content: 'Anticipate challenges before they arise. Our proactive problem-solving approach empowers us to identify and address potential issues swiftly, keeping your projects on track and successful outcomes within reach.',
            vid: puzzle,
            vidW: puzzleW,
        }
    ]
    const main = useRef(null)
    const con = useRef(null)
    useLayoutEffect(() => {
        let mm = gsap.matchMedia()
        mm.add("(max-width: 478px)", () => {
            gsap.from(".why-header", {
                y: 100,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: ".why-header",
                    start: "top 75%",
                }
            })
            let chs = gsap.utils.toArray(con.current.children)
            chs.forEach((val, index) => {
                gsap.from(val, {
                    y: 100,
                    opacity: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: val,
                        start: "top 75%",
                    }
                })
            })
        })
        mm.add("(min-width: 479px)", () => {
            gsap.from(".why-header", {
                y: 100,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: ".why-header",
                    start: "top 75%",
                    end: "+=10%",
                }
            })
            let chs = gsap.utils.toArray(con.current.children)
            chs.forEach((val, index) => {
                if (index % 2 === 0) {

                    gsap.from(val.firstChild, {
                        xPercent: -50,
                        opacity: 0,
                        scrollTrigger: {
                            trigger: val.firstChild,
                            start: "top 75%",
                            end: "+=30%",
                            scrub: true,
                        }
                    })
                    gsap.from(val.lastChild, {
                        xPercent: 50,
                        opacity: 0,
                        scrollTrigger: {
                            trigger: val.lastChild,
                            scrub: true,
                            end: "+=30%",
                            start: "top 75%",
                        }
                    })
                } else {
                    gsap.from(val.firstChild, {
                        xPercent: 50,
                        opacity: 0,
                        scrollTrigger: {
                            trigger: val.firstChild,
                            start: "top 75%",
                            end: "+=30%",
                            scrub: true,
                        }
                    })
                    gsap.from(val.lastChild, {
                        xPercent: -50,
                        opacity: 0,
                        scrollTrigger: {
                            trigger: val.lastChild,
                            scrub: true,
                            end: "+=30%",
                            start: "top 75%",
                        }
                    })
                }
            })

        })
        return () => mm.revert()
    }, [])
    return (
        <div className='why-main' ref={main}>
            <div className="why-header">
                <h1 className="whyus">Why Choose Us?</h1>
            </div>
            <div className="why-main-container" ref={con}>
                {data.map((val, index) => {
                    return (
                        <div className="why-sub-container" key={index}>
                            <div className="why-image">
                                <div className="why-image-container">
                                    <video autoPlay loop muted playsInline disableRemotePlayback className='why-vid'>
                                        <source src={val.vid} type='video/mp4' />
                                        <source src={val.vidW} type='video/webm' />
                                    </video>
                                </div>
                            </div>
                            <div className="why-content">
                                <div className="why-content-box">
                                    <h1 className="why-content-heading">{val.title}</h1>
                                    <p className='why-content-p'>{val.content}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Why