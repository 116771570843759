import React, { useLayoutEffect, useRef, useState } from 'react'
import './Contact.scss'
import './ContactMobile.scss'
import { gsap } from 'gsap'
import { VscLoading } from "react-icons/vsc";

function Contact() {
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [requirements, setRequirements] = useState()
    const [submitText, setSubmitText] = useState("Submit")
    const [loading, setLoading] = useState(false)
    const main = useRef(null)
    const contactCon = useRef(null)
    const isEmpty = (s) => {
        let flag = false
        console.log(s)
        s.forEach(element => {
            if (element === undefined) {
                flag = true
            }
            else if (element.trim() === "") {
                flag = true
            }
        });
        return flag
    }
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            gsap.from(contactCon.current, {
                y: 100,
                opacity: 0,
                duration: 1,
                scrollTrigger: {
                    trigger: contactCon.current,
                    start: "top 75%",
                }
            })
        }, main.current)
        return () => ctx.revert()
    }, [])
    const resetForm = () => {
        setName("")
        setEmail("")
        setPhone("")
        setRequirements("")
    }
    const successForm = () => {
        setLoading(false)
        resetForm()
        setSubmitText("Message Send Successfully.")
        setTimeout(() => {
            setSubmitText("Submit")
        }, 5000)
    }
    const failedForm = (e) => {
        setLoading(false)
        setSubmitText("Submit")
        console.log("Error form submission:", e.message)
    }
    const handleSubmit = () => {
        const url = "https://script.google.com/macros/s/AKfycbzyUjykjOm4VBgxg5Lsk43RIjS5ripRjGzVbKB_lGoKn5UiZSeFjEKx1PoE_ampX7h0uw/exec"
        if (isEmpty([name, phone, email, requirements])) {
            alert("Fill all the fields")
            return
        }
        setLoading(true)
        const data = new FormData();
        data.append('name', name);
        data.append('email', email);
        data.append('phone', phone);
        data.append('requirements', requirements);
        fetch(url, {
            method: "POST",
            body: data
        }).then(response => response.json())
            .then(response => successForm())
            .catch(error => failedForm(error))
    }
    return (
        <div className="contact-main" ref={main}>
            <div className="contact-container" ref={contactCon}>
                <div className="contact-header">
                    <h1 className="contact-heading">Contact Us</h1>
                </div>
                <div className="contact-form-container">
                    <div className="contact-form">
                        <span className='contact-form-name-email'>
                            <div className="contact-text-container">
                                <input type="text" className="contact-text" onChange={e => setName(e.target.value)} value={name} required autocomplete='new-user-email' />
                                <label className="label">Full Name</label>
                            </div>
                            <div className="contact-text-container">
                                <input type="text" className="contact-text" onChange={e => setPhone(e.target.value)} value={phone} required autocomplete='new-user-email' />
                                <label className="label">Phone</label>
                            </div>
                        </span>
                        <div className="contact-text-container">
                            <input type="text" className="contact-text" onChange={e => setEmail(e.target.value)} value={email} required autocomplete='new-user-email' />
                            <label className="label">Email</label>
                        </div>
                        <div className="contact-text-container flex-start">
                            <textarea className="contact-text textarea" onChange={e => setRequirements(e.target.value)} value={requirements} required />
                            <label className="label">Requirements</label>
                        </div>
                        <button className="submit-button" onClick={() => { !loading && handleSubmit() }}>{loading ? <VscLoading className='loading-rotate' /> : submitText}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact